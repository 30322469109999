<template>
  <el-dialog
    v-model="showSetupAuthenticationDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-setup-authentication-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-setup-authentication-dialog-header__title">
        {{ t('title.setupTwoFactorAuthentication') }}
      </h4>
    </template>
    <div v-loading="loading">
      <div class="elv-setup-authentication-qrcode-content">
        <img :src="qrCodeUrl" alt="qrCode" />
      </div>
      <div class="elv-setup-authentication-content__title">{{ t('title.configureYourTwoFactorClient') }}</div>
      <p class="elv-setup-authentication-content__info">
        {{ t('desc.configureYourTwoFactorClientInfo') }}
      </p>
      <p class="elv-setup-authentication-content__info">{{ t('desc.configureYourTwoFactorClientManuallyInfo') }}</p>
      <p class="elv-setup-authentication-content__value">{{ secretText }}</p>
      <div class="line" />
      <div class="elv-setup-authentication-content__title">{{ t('title.securelyStoreYourBackupCodes') }}</div>
      <p class="elv-setup-authentication-content__info">
        {{ t('desc.securelyStoreBackupCodesInfo') }}
      </p>
      <p v-for="(item, index) in backupCodes" :key="index" class="elv-setup-authentication-content__value">
        {{ item }}
      </p>
      <div class="line" />
      <div class="elv-setup-authentication-content__title">{{ t('title.verifyTheConfiguration') }}</div>
      <p class="elv-setup-authentication-content__info">
        {{ t('desc.verifyTheConfigurationInfo') }}
      </p>
      <el-form ref="formRef" :model="accountSettingsForm" label-position="top" :rules="rules" @submit.prevent>
        <el-form-item label="" prop="authCode"> <el-input v-model="accountSettingsForm.authCode" /></el-form-item>
      </el-form>
    </div>
    <template #footer>
      <elv-button height="44" width="100" round type="primary" :loading="saveLoading" @click="onEnableTwoFactorAuth">{{
        t('button.next')
      }}</elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const { t } = useI18n()
const userGlobalStore = useUserGlobalStore()

const captchaError = ref(false)
const loading = ref(true)
const saveLoading = ref(false)
const formRef = ref<FormInstance>()
const showSetupAuthenticationDialog = ref(false)
const qrCodeUrl = ref('')
const secretText = ref('')
const backupCodes = ref([])
const accountSettingsForm = reactive({
  authCode: ''
})

const rules = reactive<FormRules>({
  authCode: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (captchaError.value && value) {
          return new Error(`${t('message.captchaError')}`)
        }
        if (!value) {
          return new Error(`${t('message.captchaWarning')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ]
})

/**
 * @description: 切换弹窗展示
 * @return {*}
 */
const onCheckSetupAuthenticationDialog = () => {
  showSetupAuthenticationDialog.value = !showSetupAuthenticationDialog.value
}

/**
 * @description: 关闭弹窗
 * @return {*}
 */
const onCloseDialog = () => {
  formRef.value?.resetFields()
  captchaError.value = false
  qrCodeUrl.value = ''
  secretText.value = ''
  backupCodes.value = []
}

/**
 * @description: 启用两步验证
 * @return {*}
 */
const onEnableTwoFactorAuth = async () => {
  if (!formRef.value) return
  await formRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        captchaError.value = false
        await UserApi.userTwoFactorAuthEnable(accountSettingsForm)
        ElMessage.success(t('message.saveSuccess'))
        onCheckSetupAuthenticationDialog()
        userGlobalStore.userInit()
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      } finally {
        saveLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckSetupAuthenticationDialog, showSetupAuthenticationDialog })

watch(
  () => showSetupAuthenticationDialog.value,
  async () => {
    if (showSetupAuthenticationDialog.value) {
      try {
        loading.value = true
        const { data } = await UserApi.userTwoFactorAuthGenerate()
        qrCodeUrl.value = data.imgUrl
        secretText.value = data.secret
        backupCodes.value = data.backupCodes
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      } finally {
        loading.value = false
      }
    }
  }
)
</script>

<style lang="scss">
.elv-setup-authentication-dialog {
  width: 500px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-setup-authentication-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-setup-authentication-qrcode-content {
      display: flex;
      width: 176px;
      height: 176px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #edf0f3;
      margin: 0 auto 8px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .elv-setup-authentication-content__title {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .elv-setup-authentication-content__info {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
      word-break: break-word;
    }

    .elv-setup-authentication-content__value {
      color: #1753eb;
      font-family: 'Barlow';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .line {
      width: 452px;
      height: 1px;
      background: #edf0f3;
      margin: 16px 0;
    }

    .el-input {
      width: 320px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form {
      width: fit-content;
      margin: 0 auto;
    }

    .el-form-item {
      margin-bottom: 16px;
      width: fit-content;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
