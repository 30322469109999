<template>
  <el-dialog
    v-model="showChangePasswordDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-change-password-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-change-password-dialog-header__title">
        {{ t('button.accountSettings') }}
      </h4>
    </template>

    <el-form ref="formRef" :model="accountSettingsForm" label-position="top" :rules="rules">
      <el-form-item :label="t('title.currentPassword')" prop="oldPassword"
        ><el-input v-model="accountSettingsForm.oldPassword" type="password"
      /></el-form-item>
      <el-form-item :label="t('title.newPassword')" prop="newPassword"
        ><el-input v-model="accountSettingsForm.newPassword" type="password"
      /></el-form-item>
      <el-form-item v-if="user?.twoFactorAuth" :label="t('title.twoFactorVerificationCode')" prop="authCode">
        <el-input v-model="accountSettingsForm.authCode"
      /></el-form-item>
    </el-form>
    <template #footer>
      <elv-button height="44" width="100" round type="primary" :loading="saveLoading" @click="onSavePassword">{{
        t('button.save')
      }}</elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const { t } = useI18n()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const formRef = ref<FormInstance>()
const saveLoading = ref(false)
const captchaError = ref(false)
const passwordError = ref(false)
const showChangePasswordDialog = ref(false)
const accountSettingsForm = reactive({
  oldPassword: '',
  newPassword: '',
  authCode: ''
})

const rules = reactive<FormRules>({
  oldPassword: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (passwordError.value && value) {
          return new Error(`${t('message.passwordError')}.`)
        }
        if (!value) {
          return new Error(`${t('message.pleaseInputPassword')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ],
  newPassword: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputPassword')}`)
        }
        // eslint-disable-next-line no-useless-escape
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{12,20}$/.test(value)) {
          return new Error(`${t('message.passwordError')}.`)
        }
        return true
      }
    }
  ],
  authCode: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (captchaError.value && value) {
          return new Error(`${t('message.captchaError')}`)
        }
        if (!value) {
          return new Error(`${t('message.captchaWarning')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ]
})

/**
 * @description: 切换弹窗展示
 * @return {*}
 */
const onCheckChangePasswordsDialog = () => {
  showChangePasswordDialog.value = !showChangePasswordDialog.value
}

/**
 * @description: 关闭弹窗
 * @return {*}
 */
const onCloseDialog = () => {}

/**
 * @description: 保存密码
 * @return {*}
 */
const onSavePassword = async () => {
  if (!formRef.value) return
  await formRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        captchaError.value = false
        passwordError.value = false
        const params: any = {
          oldPassword: accountSettingsForm.oldPassword,
          newPassword: accountSettingsForm.newPassword
        }
        if (user.value?.twoFactorAuth) {
          params.authCode = accountSettingsForm.authCode
        }
        await UserApi.editUserPassword(params)
        ElMessage.success(t('message.saveSuccess'))
        onCheckChangePasswordsDialog()
        userGlobalStore.logout()
        window.location.reload()
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      } finally {
        saveLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckChangePasswordsDialog, showChangePasswordDialog })

watch(
  () => showChangePasswordDialog.value,
  async () => {
    if (showChangePasswordDialog.value) {
      formRef.value?.resetFields()
    }
  }
)
</script>

<style lang="scss">
.elv-change-password-dialog {
  width: 500px;
  min-height: 308px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-change-password-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .el-input {
      width: 452px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;
      width: 452px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-accounts-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
