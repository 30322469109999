<template>
  <el-dialog
    v-model="showBackupCodesDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-backup-codes-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-backup-codes-dialog-header__title">
        {{ t('title.backupCodes') }}
      </h4>
    </template>
    <template v-if="!backupCodes.length">
      <h5 class="elv-backup-codes-dialog-body__title">{{ t('title.createNewSetBackupCodes') }}</h5>
      <p v-if="!isShowAuth" class="elv-backup-codes-dialog-body__desc">{{ t('desc.createNewBackupCodesInfo') }}</p>
      <el-form v-else ref="formRef" :model="accountSettingsForm" label-position="top" :rules="rules">
        <el-form-item :label="t('common.yourPassword')" prop="password"
          ><el-input v-model="accountSettingsForm.password" type="password"
        /></el-form-item>
        <el-form-item :label="t('title.twoFactorVerificationCode')" prop="authCode">
          <el-input v-model="accountSettingsForm.authCode"
        /></el-form-item>
      </el-form>
    </template>
    <template v-else>
      <h5 class="elv-backup-codes-dialog-body__title">{{ t('title.securelyStoreYourBackupCodes') }}</h5>
      <p class="elv-backup-codes-dialog-body__desc">{{ t('desc.securelyStoreBackupCodesInfo') }}</p>
      <p v-for="(item, index) in backupCodes" :key="index" class="elv-backup-codes-dialog-body__value">{{ item }}</p>
    </template>

    <template #footer>
      <elv-button
        v-if="!backupCodes.length"
        height="44"
        width="126"
        round
        type="primary"
        :loading="saveLoading"
        @click="onContinue"
        >{{ t('button.continue') }}</elv-button
      >
      <elv-button v-else height="44" width="126" round type="primary" @click="onCheckBackupCodesDialog">{{
        t('common.done')
      }}</elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'

const { t } = useI18n()

const saveLoading = ref(false)
const captchaError = ref(false)
const passwordError = ref(false)
const isShowAuth = ref(false)
const backupCodes = ref([])
const formRef = ref<FormInstance>()
const showBackupCodesDialog = ref(false)
const accountSettingsForm = reactive({
  password: '',
  authCode: ''
})

const rules = reactive<FormRules>({
  password: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (passwordError.value && value) {
          return new Error(`${t('message.passwordError')}.`)
        }
        if (!value) {
          return new Error(`${t('message.pleaseInputPassword')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ],
  authCode: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (captchaError.value && value) {
          return new Error(`${t('message.captchaError')}`)
        }
        if (!value) {
          return new Error(`${t('message.captchaWarning')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ]
})

/**
 * @description: 切换弹窗展示
 * @return {*}
 */
const onCheckBackupCodesDialog = () => {
  showBackupCodesDialog.value = !showBackupCodesDialog.value
}

/**
 * @description: 展示备份码弹窗
 * @return {*}
 */
const onContinue = async () => {
  if (!isShowAuth.value) {
    isShowAuth.value = true
  } else {
    if (!formRef.value) return
    await formRef.value.validate(async (valid: boolean) => {
      if (valid) {
        try {
          saveLoading.value = true
          captchaError.value = false
          passwordError.value = false
          const { data } = await UserApi.userTwoFactorAuthRegenerateBackupCodes(accountSettingsForm)
          backupCodes.value = data.backupCodes
        } catch (error: any) {
          console.log(error)
          ElMessage.error(error.message)
        } finally {
          saveLoading.value = false
        }
      }
    })
  }
}

/**
 * @description: 关闭弹窗
 * @return {*}
 */
const onCloseDialog = () => {
  captchaError.value = false
  passwordError.value = false
  isShowAuth.value = false
  backupCodes.value = []
  formRef.value?.resetFields()
}

defineExpose({ onCheckBackupCodesDialog, showBackupCodesDialog })

watch(
  () => showBackupCodesDialog.value,
  async () => {
    if (showBackupCodesDialog.value) {
      console.log(3)
    }
  }
)
</script>

<style lang="scss">
.elv-backup-codes-dialog {
  width: 500px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-backup-codes-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-backup-codes-dialog-body__title {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    .elv-backup-codes-dialog-body__desc {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 8px;
    }

    .elv-backup-codes-dialog-body__value {
      color: #1753eb;
      font-family: 'Barlow';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 8px;
    }

    .el-input {
      width: 320px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form {
      margin: 16px auto 0;
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-accounts-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
